//
//
//
//
//
//
//
//
//
//
//
//

import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { NotificationPermissions } from '@/modules/notification/notification-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-notification-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'notification/list/hasRows',
      loading: 'notification/list/loading',
      exportLoading: 'notification/list/exportLoading',
      selectedRows: 'notification/list/selectedRows',
      destroyLoading: 'notification/destroy/loading',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new NotificationPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new NotificationPermissions(this.currentUser).edit;
    },

    hasPermissionToImport() {
      return new NotificationPermissions(this.currentUser).import;
    },

    hasPermissionToDestroy() {
      return new NotificationPermissions(this.currentUser)
        .destroy;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? i18n('common.noDataToExport')
        : null;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    destroyButtonDisabled() {
      return (
        !this.selectedRows.length ||
        this.loading ||
        this.destroyLoading
      );
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'notification/list/doExport',
      doRemoveAllSelected:
        'notification/list/doRemoveAllSelected',
      doDisableAllSelected:
        'notification/list/doDisableAllSelected',
      doEnableAllSelected:
        'notification/list/doEnableAllSelected',
      doDestroyAll: 'notification/destroy/doDestroyAll',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroyAll(
          this.selectedRows.map((item) => item.id),
        );
      } catch (error) {
        // no
      }
    },
  },
};
