//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationListFilter from '@/modules/notification/components/notification-list-filter.vue';
import NotificationListTable from '@/modules/notification/components/notification-list-table.vue';
import NotificationListToolbar from '@/modules/notification/components/notification-list-toolbar.vue';

export default {
  name: 'app-notification-list-page',

  components: {
    [NotificationListFilter.name]: NotificationListFilter,
    [NotificationListTable.name]: NotificationListTable,
    [NotificationListToolbar.name]: NotificationListToolbar,
  },

    computed:{
     
        isRTL() {
      return this.$rtl.isRTL;
    },
  },
};
