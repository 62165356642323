//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { NotificationModel } from '@/modules/notification/notification-model';
import { mapGetters, mapActions } from 'vuex';
import { NotificationPermissions } from '@/modules/notification/notification-permissions';
import { i18n } from '@/i18n';

const { fields } = NotificationModel;

export default {
  name: 'app-notification-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'notification/list/rows',
      count: 'notification/list/count',
      loading: 'notification/list/loading',
      pagination: 'notification/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'notification/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new NotificationPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new NotificationPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'notification/list/doChangeSort',
      doChangePaginationCurrentPage:
        'notification/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'notification/list/doChangePaginationPageSize',
      doMountTable: 'notification/list/doMountTable',
      doDestroy: 'notification/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return NotificationModel.presenter(row, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
